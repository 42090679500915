import React from 'react';
import {
  CodeSnippet,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text, Spinner } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../data/snippets/spinner.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Spinner: Spinner }}
        code={snippet}
        platform="react"
        gitHubLink="spinner"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="size" types={['oneOf', 'string']}>
            <Text>Determines the spinner’s size.</Text>
            <List type="unordered">
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code> (default)
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="spinningState" types={['oneOf', 'string']}>
            <Text>
              Determines whether the spinner is spinning. The stopped state will
              display a wind down animation before fading out.
            </Text>
            <List type="unordered">
              <li>
                <code>spinning</code> (default)
              </li>
              <li>
                <code>stopped</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="onSpinningStopped" types={['func']}>
            <Text>
              After the <code>spinningState</code> is set to{' '}
              <code>stopped</code>, this will fire after the animation has
              completed.
            </Text>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique string to control this spinner in automated tests.
            </Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
